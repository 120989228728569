import { useMediaQuery } from 'react-responsive';

export default function useBreakpoint() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const isBigScreen = useMediaQuery({ minWidth: 1800 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const isVerySmallScreen = useMediaQuery({ maxWidth: 480 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isRetina = useMediaQuery({ minResolution: '2dppx' });

  return {
    isTabletOrMobile,
    isBigScreen,
    isSmallScreen,
    isVerySmallScreen,
    isPortrait,
    isRetina,
  };
}
